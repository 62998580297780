
import React, { useState } from 'react'
import Main from './Main'
import messageIcon from '../../assets/img/message.jpg'
import closeIcon from '../../assets/img/exit.png';
//import the css here

export const PopChat = (props) => {


    let hide = {
        display: 'none',
    }
    let show = {
        display: 'block'
    }
    let textRef = React.createRef()
    const { messages } = props

    const [chatopen, setChatopen] = useState(false)

    const toggle = e => {
        setChatopen(!chatopen)
    }

    const handleSend = e => {
        const get = props.getMessage
        get(textRef.current.value)
    }

    return (
        <div >
            <div className="chat-box" style={chatopen ? show : hide}>
                <Main />
            </div>
            <div className="pop">
                {chatopen ?
                    <p><img onClick={toggle} src={closeIcon} alt="" /></p> :
                    <p><img onClick={toggle} src={messageIcon} alt="" /></p>
                }

            </div>
        </div>
    )
}

export default PopChat