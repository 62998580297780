const services = [
  {
    id: 1,
    description: "Service center for Supermicro",
    image: require("../assets/img/service/1.jpg"),
  },
  {
    id: 2,
    description: "Network setup, maintenance and configuration for enterprises",
    image: require("../assets/img/service/2.jpg"),
  },
  {
    id: 3,
    description: "Network components and cables",
    image: require("../assets/img/service/3.jpg"),
  },
  {
    id: 4,
    description:
      "Network/Desktop Security (Firewall, Anti-Virus, Anti-Spam, Phishing etc.)",
    image: require("../assets/img/service/4.jpg"),
  },
  {
    id: 5,
    description: "Backup Solutions, Disaster Recovery Configuration",
    image: require("../assets/img/service/5.jpg"),
  },
  {
    id: 6,
    description: "Electronic Business Solutions",
    image: require("../assets/img/service/6.jpg"),
  },
  {
    id: 7,
    description: "VPN Client Services - VOIP (Voice Over Internet Protocol)",
    image: require("../assets/img/service/7.jpg"),
  },
  {
    id: 8,
    description: "A Wide choice of computer systems",
    image: require("../assets/img/service/8.jpg"),
  },
  {
    id: 9,
    description: "System Update Services",
    image: require("../assets/img/service/9.jpg"),
  },
];

export default services;
