import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Announcement from "react-announcement";
import Logo from "../assets/img/logos/announcement-small.png";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4" style={{ lineHeight: 3.5 }}>
            <h2>EXEL-TECH</h2>
            <ul className="list-inline social-buttons">
              <li className="list-inline-item">
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="fab fa-google"></i>
                </a>
              </li>
            </ul>
            <span className="copyright">
              {t("Copyright")}&copy;Exel-Tech {new Date().getFullYear()}
            </span>
            <p>{t("All rights reserved")}</p>
          </div>
          <div className="col-md-4">
            <div>
              <h2 key="location">{t("LOCATION")}</h2>
              <ul className="list-inline">
                <li>Phone: (1) 514-335-1300</li>
                <li>Cell: (1) 514-953-4203</li>
                <li>
                  <a href="mailto:info@exel-tech.com">
                    Email: info@exel-tech.com
                  </a>
                </li>
                <li>
                  <a href="https://goo.gl/maps/nHAWheRWiH6kACa29">
                    Address: 1240 Rue Bégin Saint-Laurent, QC H4R 1X1 Canada
                  </a>
                </li>
                <li>{t("Monday to Friday")} 09:00 AM - 05:00 PM</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <h2>{t("OTHERS")}</h2>
            <ul className="list-inline quicklinks">
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/products">{t("products")}</Link>
              </li>
              <li>
                <Link to="/about">{t("about")}</Link>
              </li>
              <li>
                <Link to="/contact">{t("contact")}</Link>
              </li>
              <li>
                <Link to="/dashboard">{t("product inquiry")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <small style={{ color: "white" }}>Created by Hang Ruan</small>
      <Announcement
        title="We’ve moved"
        subtitle="Exel-Tech has recently moved to a new location at 1240 Rue Bégin Saint-Laurent, QC H4R 1X1"
        link="https://goo.gl/maps/nHAWheRWiH6kACa29"
        imageSource={Logo}
        closeIconSize={25}
        daysToLive={3}
        secondsBeforeBannerShows={3}
      />
    </footer>
  );
};

export default Footer;
